import { gql } from "@apollo/client";

export const GET_SERVICELINEPRICING_BY_PRACTICE = gql`
  query serviceLinePricingById($input: ServiceLinePricingByIdInput!) {
    serviceLinePricingById(input: $input) {
      serviceLineId
      currencyCode
      prices {
        consultType
        basePrice {
          price
          totalVisitCharge
          zeroPriceForHhsProvider
        }
      }
    }
  }
`;

export const UPDATE_SERVICE_LINE_PRICING = gql`
  mutation updateServiceLinePricing($input: UpdateServiceLinePricingInput!) {
    updateServiceLinePricing(input: $input) {
      errors {
        __typename
        ... on IBaseError {
          message
        }
      }
    }
  }
`;
