import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";
import {
  Breadcrumb,
  DefaultInsurancePaymentContext,
  InsurancePaymentContext,
} from "../../serviceInterface";
import { BrowserHistory } from "history";
import { AppVersion } from "./appversion";
import { Context } from "react";

function getParentPath(rootPath: string) {
  const mfeRootLevels = [
    "service-line",
    "dynamic-template",
    "template-preview",
    "insurance-payment",
  ];

  for (var i = 0; i < mfeRootLevels.length; i++) {
    var matchPos = (rootPath + "/").lastIndexOf("/" + mfeRootLevels[i] + "/");
    if (matchPos > -1) {
      return rootPath.substring(0, matchPos);
    }
  }
  return "/";
}

export type HostAppType = "SUPERADMIN" | "PROVIDER" | "OTHER";

export default class CommonStore {
  rootStore: RootStore;
  storeInitialized = false;
  appLoaded = false;
  hidePatientScheduled = false;
  hostApp: HostAppType;
  history: BrowserHistory;
  organizationId!: string;
  practiceId!: string;
  hostPath: () => string = () => {
    return window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf("/service-line"),
    );
  };
  rootPath: () => string = () => {
    return window.location.pathname
      .replace("/superadmin", "")
      .replace(/service-line\/.*/, "service-line");
  };
  parentPath: () => string = () => {
    return getParentPath(this.rootPath());
  };
  appTop: number = 0;
  appLeft: number = 0;
  setBreadCrumbs: (breadcrumbs: Breadcrumb[]) => void = () => {};
  setHeader: (title: string) => void = () => {};
  setSavePaymentsConfigCallback: (
    savePaymentsConfig: (serviceLineId: string) => boolean,
  ) => void = () => {};
  setPaymentsConfigHasChangesCallback: (
    paymentsConfigHasChanges: () => boolean,
  ) => void = () => {};
  idpToken: () => Promise<string> = () =>
    new Promise<string>((resolve) => {
      resolve("");
    });

  insurancePaymentContext: Context<InsurancePaymentContext> =
    DefaultInsurancePaymentContext;

  constructor(rootStore: RootStore) {
    this.history = {} as BrowserHistory;
    this.rootStore = rootStore;
    this.hostApp = "OTHER";
    var startUpValues = {
      logType: "Startup Values",
      version: AppVersion,
      hostPath: this.hostPath(),
      rootPath: this.rootPath(),
      location: window.location,
    };
    console.log(startUpValues);

    makeObservable(this, {
      appLoaded: observable,
      setAppLoaded: action,
    });
  }

  setStoreInitialized = () => (this.storeInitialized = true);
  setAppLoaded = () => (this.appLoaded = true);
  setAppLocation = (top: number, left: number) => {
    this.appTop = top;
    this.appLeft = left;
  };
  setHostApp = (hostApp: string | undefined) => {
    if (hostApp?.toUpperCase() === "SUPERADMIN") {
      this.hostApp = "SUPERADMIN";
    } else if (hostApp?.toUpperCase() === "PROVIDER") {
      this.hostApp = "PROVIDER";
    } else {
      this.hostApp = "OTHER";
    }
  };
  setHistory = (history: any) => (this.history = history);
  setOrganizationId = (organizationId: string) =>
    (this.organizationId = organizationId);
  setPracticeId = (practiceId: string) => (this.practiceId = practiceId);
  setHidePatientScheduled = (value: boolean) =>
    (this.hidePatientScheduled = value);

  InitializeBreadCrumbsSetter = (func: (breadcrumbs: Breadcrumb[]) => void) =>
    (this.setBreadCrumbs = func);
  InitializeHeaderSetter = (func: (title: string) => void) =>
    (this.setHeader = func);
  InitializeSavePaymentsConfigCallback = (
    func: (savePaymentsConfig: (serviceLineId: string) => boolean) => void,
  ) => (this.setSavePaymentsConfigCallback = func);
  InitializePaymentsConfigHasChangesCallback = (
    func: (paymentsConfigHasChanges: () => boolean) => void,
  ) => (this.setPaymentsConfigHasChangesCallback = func);
  InitializeIdpTokenFunc = async (func: () => Promise<string>) =>
    (this.idpToken = func);
}
