import { useQuery, useMutation } from "@apollo/client";
import { useInsurancePaymentClient } from "../../app/stores/insurancePaymentStore";
import {
  GET_SERVICELINEPRICING_BY_PRACTICE,
  UPDATE_SERVICE_LINE_PRICING,
} from "./BasePriceGraphQlDefs";

export interface ServiceLinePriceType {
  price: number;
  totalVisitCharge: number;
  zeroPriceForHhsProvider: boolean;
}

export interface ServiceLineConsultPricingType {
  consultType: string;
  basePrice: ServiceLinePriceType;
  zeroPriceForHhsProvider: boolean;
}

export interface ServiceLinePricingType {
  serviceLineId: string;
  currencyCode: string;
  prices: ServiceLineConsultPricingType[];
}

export function useServiceLinePricingQuery(serviceLineId: string) {
  const insurancePaymentClient = useInsurancePaymentClient();
  const input = {
    input: {
      serviceLineId: serviceLineId,
    },
  };

  return useQuery<{ serviceLinePricingById: ServiceLinePricingType }>(
    GET_SERVICELINEPRICING_BY_PRACTICE,
    {
      variables: input,
      client: insurancePaymentClient,
    },
  );
}

export function useUpdateServiceLinePricingMutation() {
  const insurancePaymentClient = useInsurancePaymentClient();

  return useMutation(UPDATE_SERVICE_LINE_PRICING, {
    client: insurancePaymentClient,
  });
}
